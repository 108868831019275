import { rubberEventHanlder } from "../Events/rubberEventHandler";

/**
 * Init scene two
 */
export default function sceneTwo() {
  if (!window.mbArScene || !window.mbArProject) return;

  // Init rubber event hanlder
  rubberEventHanlder(3, null);

  const mbArScene = window.mbArScene;
  const mbArProject = window.mbArProject;

  // Event handler function
  const sceneTwoEventHandler = () => {
    console.log("Scene 2 starts running");

    mbArScene.animationCouldBeRun = true;
    mbArScene.initAnimations(mbArProject.activeSubstring + "*"); // Init animations

    // remove listener after triggering
    window.removeEventListener("mbRubberEventX", sceneTwoEventHandler);
  };

  // Add custom event listener
  window.addEventListener("mbRubberEventX", sceneTwoEventHandler);
}
