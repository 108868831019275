export function handleMarker(mbArScene) {
  /**
   * On marker found
   */
  mbArScene.marker.addEventListener("markerFound", (e) => {
    console.log("marker found");
    mbArScene.markerFound = true;
    mbArScene.runAnimation();
  });

  /**
   * On marker lost
   */
  mbArScene.marker.addEventListener("markerLost", (e) => {
    console.log("marker lost");
    mbArScene.markerFound = false;
    mbArScene.pauseAnimation();
  });
}
