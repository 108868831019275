/**
 * Init scene one
 */
export default function sceneOne() {
  if (!window.mbArScene || !window.mbArProject) return;

  const mbArScene = window.mbArScene;
  const mbArProject = window.mbArProject;

  const overlay = document.createElement("div.overlay");

  /**
   * Eventhandler befor start animation
   */
  const sceneOneEventHandler = () => {
    console.log("Scene 1 starts running");

    mbArScene.animationCouldBeRun = true;
    mbArScene.initAnimations(mbArProject.activeSubstring + "*"); // Init animations

    // remove listener after triggering
    window.removeEventListener("click", sceneOneEventHandler);
  };

  /**
   * Show start overlay
   */
  const showStartOverlay = () => {
    console.log("HALLO", overlay);
    // Add custom event listener
    window.addEventListener("click", sceneOneEventHandler);
  };

  showStartOverlay();
}
