/**
 * Print developer message in browser
 */
export function printDeveloperMessageToConsole() {
  console.log(
    "%cAR adventure by markenteam behind you",
    "color: #e6b381; font-size: x-large"
  );
  console.log(
    "%cmbagentur.de / hallo@mbagentur.de",
    "color: darkgrey; font-size: large"
  );
}
