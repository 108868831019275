import { handleMarker } from "../Handler/markerHandler";
import { modelInitEventlisteners } from "../Handler/modelHandler";

export default class MbArScene {
  component = null;
  model = null;
  modelAnimations = null;
  modelAnimationNames = null;
  modelCurrentAnimationsCount = 0;
  modelFinishedAnimationsCount = 0;
  marker = null;
  scene = null;

  #animationCouldBeRun = false;
  #markerFound = false;

  constructor(aframe, componentName) {
    const that = this;

    aframe.registerComponent(componentName, {
      // on init
      init: function () {
        that.component = this;
        that.model = this.el;
        that.scene = this.el.sceneEl;
        that.marker = that.scene.querySelector("a-marker");

        that.updateAnimationMixer("timeScale", "0");
        that.animationCouldBeRun = false;

        console.log("INIT:", that);

        modelInitEventlisteners(that);
        handleMarker(that);
      },
      // on every tick
      tick: function () {},
    });
  }

  /**--------- METHODS --------------------------------- */

  /**
   * Init animation mixer
   * and select clips
   * Docu: https://github.com/c-frame/aframe-extras/blob/master/src/loaders/README.md
   * @param {string} selector
   */
  initAnimations(selector = "*") {
    this.modelCurrentAnimationsCount = 0;
    this.modelFinishedAnimationsCount = 0;

    this.updateAnimationMixer({
      clip: selector,
      preload: "auto",
      autoplay: "false",
      timeScale: 0, // 1 normal speed, 0 pause
      loop: "once", // repeat | once
      clampWhenFinished: true,
      // repetitions: 1,
    });

    // Count matching clips
    const searchString = selector.replace("*", "");

    if (Array.isArray(this.modelAnimationNames)) {
      if (searchString == "") {
        // if searchtstring ist empty, count all animations
        this.modelCurrentAnimationsCount = this.modelAnimationNames.length;
      } else {
        // if searchstring exists, find matching animations
        this.modelAnimationNames.forEach((animationName) => {
          if (animationName.startsWith(searchString)) {
            this.modelCurrentAnimationsCount++;
          }
        });
      }
    }

    // If no matching animations clips found
    if (
      !Array.isArray(this.modelAnimationNames) ||
      this.modelCurrentAnimationsCount == 0
    ) {
      console.warn("[MB AR-Framework] No matching clips found");
    } else {
      // run animation if could be
      this.runAnimation();
    }
  }

  /**
   * Run current animation
   */
  runAnimation() {
    if (this.#animationCouldBeRun == true && this.#markerFound == true) {
      this.updateAnimationMixer("timeScale", "1");
    }
  }

  /**
   * Pause current animation
   */
  pauseAnimation() {
    this.updateAnimationMixer("timeScale", "0");
  }

  /**
   * Update animation-mixer attribute
   * @param {string|object} att
   * @param {string} val
   * @returns
   */
  updateAnimationMixer(att, val) {
    if (!att || (typeof att == "string" && !val)) return;

    if (typeof att == "string") {
      this.model.setAttribute("animation-mixer", `${att}: ${val}`);
    }

    if (typeof att == "object") {
      this.model.setAttribute("animation-mixer", att);
    }
  }

  /**
   * Delete animations
   */
  deleteAnimation() {
    this.model.player.removeAttribute("animation-mixer");
  }

  /**--------- GETTER --------------------------------- */
  /**
   * Get animation could be run status
   * @returns {boolean}
   */
  get animationCouldBeRun() {
    return this.#animationCouldBeRun;
  }

  /**
   * Get marker found status
   * @returns {boolean}
   */
  get markerFound() {
    return this.#markerFound;
  }

  /**--------- SETTER --------------------------------- */
  /**
   * Set animation could be run
   * @param {boolean} couldBeRun
   */
  set animationCouldBeRun(couldBeRun) {
    this.#animationCouldBeRun = couldBeRun;
  }

  /**
   * Set animation could be run
   * @param {boolean} couldBeRun
   */
  set markerFound(markerFound) {
    this.#markerFound = markerFound;
  }
}
