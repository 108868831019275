import MbArScene from "./Classes/MbArScene";
import MbArProject from "./Classes/MbArProject";
import { printDeveloperMessageToConsole } from "./Helper/mbagentur";

import sceneOne from "./Scenes/Scene1";
import sceneTwo from "./Scenes/Scene2";

(function () {
  function init() {
    // init scene handlers
    window.sceneOne = sceneOne;
    window.sceneTwo = sceneTwo;

    // init project
    window.mbArProject = new MbArProject();

    // config project
    window.mbArProject.scenesConfig = [
      // { scene: "Ball", action: "sceneOne" },
      // { scene: "Springt", action: "sceneTwo" },
      { scene: "01", action: "sceneOne" },
      { scene: "02", action: "sceneTwo" },
    ];

    // init Aframe AR Scene
    window.mbArScene = new MbArScene(window.aframe, "mb-model");
  }

  // print MB development console message
  printDeveloperMessageToConsole();

  // init
  init();
})();
