export default class MbArProject {
  #activeAnimationKey = 0;
  #activeSubstring = null;
  #activeAction = null;
  #scenesConfig = [];

  constructor() {}

  /**--------- METHODS --------------------------------- */
  prepareScene(mbArScene) {
    if (
      !Array.isArray(this.#scenesConfig) ||
      this.#scenesConfig[this.#activeAnimationKey] == undefined
    ) {
      this.activeSubstring = null;
      return;
    }

    console.log("Prepare", mbArScene);

    // set active substring
    this.activeSubstring = this.#scenesConfig[this.#activeAnimationKey].scene;
    this.activeAction = this.#scenesConfig[this.#activeAnimationKey].action;

    if (this.#activeAction === "instant") {
      // Init animations instantly
      mbArScene.animationCouldBeRun = true;
      mbArScene.initAnimations(this.#activeSubstring + "*");
    } else if (
      this.#activeAction.startsWith("scene") &&
      typeof window[this.#activeAction] === "function"
    ) {
      // call custom function
      window[this.#activeAction](mbArScene, this.#activeSubstring);
    }
  }

  /**--------- SETTER --------------------------------- */
  /**
   * Set active animation key
   * @param {int} key
   */
  set activeAnimationKey(key) {
    this.#activeAnimationKey = key;
  }

  /**
   * Set active clip substring
   * @param {string} substring
   */
  set activeSubstring(substring) {
    this.#activeSubstring = substring;
  }

  /**
   * Set active action
   * @param {string} action
   */
  set activeAction(action) {
    this.#activeAction = action;
  }

  /**
   * Set scene config
   * @param {array} config
   */
  set scenesConfig(config) {
    this.#scenesConfig = config;
  }

  /**--------- GETTER --------------------------------- */
  /**
   * Get active animation key
   * @returns {int}
   */
  get activeAnimationKey() {
    return this.#activeAnimationKey;
  }

  /**
   * Get active clip substring
   * @returns {string}
   */
  get activeSubstring() {
    return this.#activeSubstring;
  }

  /**
   * Get active action
   * @returns {string}
   */
  get activeAction() {
    return this.#activeAction;
  }

  /**
   * Get scenes config
   * @returns {array}
   */
  get scenesConfig() {
    return this.#scenesConfig;
  }
}
