export function modelInitEventlisteners(mbArScene) {
  /**
   * Event after model loaded
   */
  mbArScene.model.addEventListener("model-loaded", (e) => {
    onModelLoaded(mbArScene);
  });

  /**
   * After animation finished
   */
  mbArScene.model.addEventListener("animation-finished", (e) => {
    onAnimationFinished(mbArScene);
  });

  /**
   * After animation loop
   */
  mbArScene.model.addEventListener("animation-loop", (e) => {
    onAnimationLoop(mbArScene);
  });

  /**
   * After marker scanned
   */
  //   mbArScene.model.addEventListener("markerscanned-or-something", () => {
  //     console.log("Marker scanned!!!");
  //   });
}

/**
 * On model loaded
 * @param {object} mbArScene
 */
function onModelLoaded(mbArScene) {
  // get list of animation
  mbArScene.modelAnimations =
    mbArScene.model.components["gltf-model"].model.animations;

  // get array of all Animation names
  mbArScene.modelAnimationNames = mbArScene.modelAnimations.map((a) => a.name);

  // prepare current scene
  window.mbArProject.prepareScene(mbArScene);

  console.log("ALL ANIMATIONS:", mbArScene.modelAnimations);
}

/**
 * After animation finished
 * @param {object} mbArScene
 */
function onAnimationFinished(mbArScene) {
  mbArScene.modelFinishedAnimationsCount++;

  console.log("Animation finished!!!");

  // check if last animation is finished
  if (
    mbArScene.modelFinishedAnimationsCount >=
    mbArScene.modelCurrentAnimationsCount
  ) {
    console.log("Last Animation finished!!!");

    mbArScene.animationCouldBeRun = false;

    // prepare next scene
    const mbArProject = window.mbArProject;
    mbArProject.activeAnimationKey++;
    mbArProject.prepareScene(mbArScene);
  }
}

/**
 * After animation looped
 */
function onAnimationLoop(mbArScene) {
  console.log("Animation looped!!!");
}
