/**
 * Fires a custom window event with data
 * @param {string} eventname
 * @param {object} data
 *
 * window.addEventListener("eventname", function (e) {
 *    console.log("Custom event", e.data);
 * });
 */
export default function dispatchCustomEvent(eventname, data = { data: null }) {
  let customWindowEvent = new CustomEvent(eventname, data);
  window.dispatchEvent(customWindowEvent);
}
