import dispatchCustomEvent from "../Helper/custom-event";

/**
 * Handle rubbing while touch move
 * @param {int} triggerX
 * @param {int} triggerY
 * @param {string} eventPrefix
 */
export function rubberEventHanlder(
  triggerX = 0,
  triggerY = 0,
  eventPrefix = "mbRubberEvent"
) {
  // counter
  let directionCounter = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };

  let oriantionCounter = {
    x: 0,
    y: 0,
  };

  //remove existing listeners
  document.removeEventListener("touchstart", handleTouchStart, false);
  document.removeEventListener("touchmove", handleTouchMove, false);

  // register new listeners
  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  // default values
  let xDown = null;
  let yDown = null;
  let xDirectionOld = null;
  let yDirectionOld = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches // jQuery
    );
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    let xDirection = null;
    let yDirection = null;

    // detect move direction
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /**
       * horizontal swipe detected
       */
      xDirection = xDiff > 0 ? "right" : "left";

      if (xDirectionOld !== xDirection) {
        directionCounter[xDirection]++;

        // update direction counter to lowser value
        oriantionCounter.x = Math.min(
          directionCounter.right,
          directionCounter.left
        );

        // fire custom event
        if (triggerX > 0 && triggerX == oriantionCounter.x) {
          dispatchCustomEvent(eventPrefix + "X");
        }
      }
    } else {
      /**
       * vertical swipe detected
       */
      yDirection = yDiff > 0 ? "bottom" : "top";

      if (yDirectionOld !== yDirection) {
        directionCounter[yDirection]++;

        // update direction counter to lowser value
        oriantionCounter.y = Math.min(
          directionCounter.top,
          directionCounter.bottom
        );

        // fire custom event
        if (triggerY > 0 && triggerY == oriantionCounter.y) {
          dispatchCustomEvent(eventPrefix + "Y");
        }
      }
    }

    /* reset values */
    xDown = xUp;
    yDown = yUp;

    xDirectionOld = xDirection;
    yDirectionOld = yDirection;
  }
}
